:root {
  --bg-color: white;
  --font-color: black;
  --border-color: black;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Mono', monospace;
  background-color: var(--bg-color);
  color: var(--font-color);
}

.App {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  padding-top: 1em;
  background-color: var(--bg-color);
}

.App-container {
  max-width: 800px;
  width: 90%;
  margin: 2em;
  padding: 2em;
  border: 3px solid var(--border-color);
  overflow-x: hidden;
  overflow-y: auto;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 3em;
  color: var(--font-color);
}

.header-content {
  display: flex;
  justify-content: flex-end; /* Updated to justify content to the right */
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  gap: 1em;
}

.main-title {
  font-size: 3em;
  font-weight: bold;
  color: var(--font-color);
  text-decoration: underline;
  text-align: center;
  flex-basis: 100%;
  order: 2;
}

.theme-toggle {
  font-size: 1.5em;
  background: none;
  color: var(--font-color);
  border: 3px solid var(--font-color);
  padding: 0.5em;
  cursor: pointer;
  transition: background 0.3s;
}

.theme-toggle:hover {
  background: var(--font-color);
  color: var(--bg-color);
}

.App-main {
  display: flex;
  flex-direction: column;
  gap: 2em;
  color: var(--font-color);
}

.column {
  flex: 1;
}

section {
  margin-bottom: 2em;
  color: var(--font-color);
}

h2 {
  font-size: 2em;
  font-weight: bold;
  color: var(--font-color);
}

a {
  color: var(--font-color);
}

a:hover {
  color: var(--border-color);
}

/* Media Query */
@media screen and (min-width: 600px) {
  .App-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header-content {
    flex-direction: row;
    gap: 1em;
  }

  .App-main {
    flex-direction: row;
  }
}
